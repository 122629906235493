@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;600;700&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: Poppins;
}

/* Cores */
:root {
  --primary-color: #f57a01;
  --secondary-color: #f5dd9c;
  --light-color: #fafafa;
  --dark-color: #2a2a2a;
  --page-background-color: #eeeeee;
  --highliht-color: #ff8c19;
  --valid-input-color: #24ff00;
  --invalid-input-color: #f50101;
}

body {
  background-color: var(--page-background-color);
  color: var(--dark-color);
}

.hidden {
  display: none;
}

.container {
  margin: 4rem auto;
  background-color: var(--light-color);
  max-width: 60rem;
  min-height: 100%;
  padding: 2rem 4rem;
  box-shadow: rgb(0 0 0 / 20%) 0.2rem 0.2rem 1rem;
  position: relative;
}

.form-wrapper {
  display: flex;
  flex-direction: column;
}

.form-title {
  font-weight: 600;
  font-size: 2rem;
  color: var(--primary-color);
  margin: 2rem 0;
}

.input-group {
  position: relative;
}

.float-label:focus-within label {
  top: -0.7rem;
  left: 2rem;
  transform: scale(0.9);
  background-color: var(--light-color);
  padding: 0 5px;
}

.form-label {
  position: absolute;
  transition: top, transform, 0.2s ease-in-out;
  font-size: 1rem;
  pointer-events: none;
}

.style-up {
  top: -0.7rem;
  left: 2rem;
  transform: scale(0.9);
  background-color: var(--light-color);
  padding: 0 5px;
}

.style-down {
  top: 1rem;
  left: 2rem;
  transform: scale(1);
}

.form-input {
  border: 1px solid var(--primary-color);
  background-color: transparent;
  border-radius: 50px;
  padding: 1rem 2rem;
  width: 100%;
  margin-bottom: 2rem;
  outline: none;
}

.van {
  margin-bottom: 0.3rem !important;
}

.form-input:focus {
  box-shadow: #f57a014d 0px 0px 0px 4px;
}

.g-recaptcha {
  align-self: center;
  padding: 0.5rem;
}

.form-check-label {
  margin-left: 10px;
  font-size: 14px;
}

.form-radio-wrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
}

.form-radio {
  width: 50%;
}

.form-button {
  align-self: center;
  width: 50%;
  padding: 0.5rem;
  border-radius: 50px;
  border: none;
  color: var(--light-color);
  font-size: 1.25rem;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  outline: none;
  position: relative;
}

.form-button:active {
  top: 2px;
}

.form-button:enabled {
  background-color: var(--primary-color);
  cursor: pointer;
}

.form-button:disabled {
  background-color: #bbb;
}

.form-button:enabled:hover {
  background-color: var(--highliht-color);
  box-shadow: #f57a014d 0px 0px 0px 4px;
}

.form-button:enabled:focus-visible {
  background-color: var(--highliht-color);
  box-shadow: #f57a014d 0px 0px 0px 4px;
}

.button-group {
  display: flex;
  padding-bottom: 2em;
  align-self: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.button-group .form-button {
  width: 49.8%;
}

.valid {
  border-color: var(--valid-input-color);
}
.valid:focus {
  box-shadow: #24ff001a 0px 0px 0px 4px;
}

.invalid {
  border-color: var(--invalid-input-color);
}
.invalid:focus {
  box-shadow: #f501011a 0px 0px 0px 4px;
}

.logo-wrapper {
  width: fit-content;
  padding: 0.75rem 2rem;
  background-color: var(--secondary-color);
  position: absolute;
  top: -2.5rem;
  left: 4rem;
  display: flex;
}

.logo-wrapper img {
  max-width: 15rem;
  align-self: center;
  justify-self: center;
}

.info-alert {
  text-align: justify;
}

.success-title {
  font-size: 2rem;
  font-weight: 700;
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: var(--primary-color);
}

.info-alert p {
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  color: var(--dark-color);
}

.info-alert li {
  margin: 0.5rem;
  margin-left: 2.5rem;
  font-size: 1.1rem;
  color: var(--dark-color);
}

.info-alert li::marker {
  font-weight: 700;
  color: var(--highliht-color);
}

.info-alert li:first-of-type {
  margin-top: 1.5rem;
}

.info-alert li:last-of-type {
  margin-bottom: 1.5rem;
}

.info-alert p:last-of-type {
  margin-bottom: 1rem;
}

.info-alert span {
  font-weight: 700;
}

.info-alert a {
  color: var(--highliht-color);
}

.info-alert hr {
  margin-bottom: 1.5rem;
  border-color: var(--highliht-color);
}

.alert {
  background-color: var(--primary-color);
  padding: 0.5rem 1rem;
  margin: 0rem 1rem;
  font-size: 0.8rem;
  border-radius: 0.1rem;
  border: none;
  position: relative;
  z-index: 2;
  border-radius: 0.3rem;
  color: var(--light-color);
}

.alert::before {
  border: none;
  background: linear-gradient(
    -45deg,
    transparent 50%,
    var(--primary-color) 50%
  );
  z-index: -1;
  content: "";
  top: -0.3rem;
  left: 1rem;
  width: 0.5rem;
  height: 0.5rem;
  transform: rotate(45deg);
  position: absolute;
}

.alert-warning {
  max-width: 30rem;
  margin: 0 auto;
  margin-top: 1rem;
  background-color: var(--primary-color);
}

.alert-warning::before {
  left: 50%;
  background-color: var(--primary-color);
}

.video {
  position: relative;
  /* Scaling  of width to height, considering a 16:9 ratio */
  padding-bottom: 56.25%;
}

.video iframe {
  position: absolute;
  width: 100%;
  height: 100%;
}

@media (max-width: 650px) {
  .logo-wrapper {
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 450px) {
  .optional {
    display: none;
  }
}

@media (max-width: 768px) {
  .form-radio-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;
  }
}

@media (max-width: 768px) {
  .form-radio {
    width: 100%;
  }
}

@media (max-width: 550px) {
  .video {
    position: relative;
    /* Scaling  of width to height, considering a 9:16 ratio */
    padding-bottom: 177.78%;
  }
}

@media (max-width: 768px) {
  .button-group {
    margin-bottom: -0.2rem;
  }

  .button-group .form-button {
    width: 100%;
    margin-bottom: 0.2rem;
  }
}